import React from 'react';
import { Link } from 'react-router-dom';
import { User } from '../../types/types';

interface UsersTableProps {
  users: User[];
}

const UsersTable: React.FC<UsersTableProps> = ({ users }) => {
  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          {/* User Column */}
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            User
          </th>
          {/* Tokens Column */}
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Tokens
          </th>
          {/* Referral Code Column */}
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Referral Code
          </th>
          {/* Actions Column */}
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {users.map((user, index) => (
          <tr key={`${user.deviceUDID}-${index}`}>
            {/* User Cell */}
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="flex items-center">
                <div className="flex-shrink-0 h-10 w-10">
                  <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center">
                    <span className="text-lg font-medium text-gray-700">
                      {user.username.slice(0, 2).toUpperCase()}
                    </span>
                  </div>
                </div>
                <div className="ml-4">
                  <div className="text-sm font-medium text-gray-900">{user.username}</div>
                  <div className="text-sm text-gray-500">{user.deviceUDID}</div>
                </div>
              </div>
            </td>
            {/* Tokens Cell */}
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.tokens}</td>
            {/* Referral Code Cell */}
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.referralCode}</td>
            {/* Actions Cell */}
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              <Link
                to={`/users/${user.deviceUDID}`}
                className="text-indigo-600 hover:text-indigo-900"
              >
                View
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default UsersTable;