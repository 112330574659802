import React, { useState, useEffect } from 'react';
import { getMonthlyLeaderboards, setMonthlyLeaderboardPrize, getMonthlyLeaderboardDetails } from '../services/api';
import { MonthlyLeaderboard } from '../types/types';
import Drawer from '../components/common/Drawer';
import Notification from '../components/common/Notification';
import SeasonSelector from '../components/common/SeasonSelector';
import { useAuth } from '../contexts/AuthContext';
import LoadingButton from '../components/common/LoadingButton';

const TokenLeaderboards: React.FC = () => {
  const { user } = useAuth();
  const [season, setSeason] = useState('2024-2025');
  const [leaderboards, setLeaderboards] = useState<MonthlyLeaderboard[]>([]);
  const [selectedLeaderboard, setSelectedLeaderboard] = useState<MonthlyLeaderboard | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [notification, setNotification] = useState({ message: '', type: 'success' });

  useEffect(() => {
    if (user && user.role === 'admin') {
      fetchLeaderboards();
    }
  }, [season, user]);

  const fetchLeaderboards = async () => {
    try {
      const response = await getMonthlyLeaderboards(season);
      setLeaderboards(response.data);
    } catch (error) {
      console.error('Error fetching leaderboards:', error);
      setNotification({ message: 'Failed to load leaderboards', type: 'error' });
    }
  };

  const openLeaderboard = async (month: string) => {
    try {
      const response = await getMonthlyLeaderboardDetails(month, season);
      setSelectedLeaderboard(response.data);
      setIsDrawerOpen(true);
    } catch (error) {
      console.error('Error fetching leaderboard details:', error);
      setNotification({ message: 'Failed to load leaderboard details', type: 'error' });
    }
  };

  const openPrizeSettings = (month: string) => {
    const prize = prompt('Enter prize for this month:');
    if (prize !== null) {
      setMonthlyLeaderboardPrize(month, prize)
        .then(() => {
          setNotification({ message: 'Prize updated successfully', type: 'success' });
          fetchLeaderboards();
        })
        .catch(error => {
          console.error('Error setting prize:', error);
          setNotification({ message: 'Failed to set prize', type: 'error' });
        });
    }
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-2xl font-semibold text-gray-900 mb-6">Token Leaderboards</h1>

      <SeasonSelector season={season} onSeasonChange={setSeason} />

      <div className="mt-6 space-y-6">
        {leaderboards.map(leaderboard => (
          <div key={leaderboard.month} className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {new Date(leaderboard.month).toLocaleString('default', { month: 'long', year: 'numeric' })}
              </h3>
              <div className="flex space-x-2">
                <LoadingButton
                  isLoading={false}
                  onClick={() => openLeaderboard(leaderboard.month)}
                >
                  View Leaderboard
                </LoadingButton>
                <LoadingButton
                  isLoading={false}
                  onClick={() => openPrizeSettings(leaderboard.month)}
                  variant="secondary"
                >
                  Set Prize
                </LoadingButton>
              </div>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500 pl-4">Prize</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {leaderboard.prize || 'No prize set'}
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500 pl-4">Winner</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {leaderboard.winner_device_udid || 'Not determined yet'}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        ))}
      </div>

      {/* Leaderboard Drawer */}
      <Drawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} title="Leaderboard Details">
        {selectedLeaderboard && selectedLeaderboard.entries && selectedLeaderboard.entries.length > 0 ? (
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rank</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Device UDID</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Username</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tokens</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Gameweeks Played</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Correct Picks</th>
              </tr>
            </thead>
            <tbody>
              {selectedLeaderboard.entries.map(entry => (
                <tr key={entry.deviceUDID}>
                  <td className="px-4 py-2">{entry.rank}</td>
                  <td className="px-4 py-2">{entry.deviceUDID}</td>
                  <td className="px-4 py-2">{entry.username}</td>
                  <td className="px-4 py-2">{entry.tokens}</td>
                  <td className="px-4 py-2">{entry.gameweeksPlayed}</td>
                  <td className="px-4 py-2">{entry.correctPicks}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No leaderboard data available.</p>
        )}
      </Drawer>

      {/* Notification */}
      {notification.message && (
        <Notification
          message={notification.message}
          type={notification.type as 'success' | 'error' | 'info'}
          onClose={() => setNotification({ message: '', type: 'success' })}
        />
      )}
    </div>
  );
};

export default TokenLeaderboards;