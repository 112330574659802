import React, { useState, useEffect } from 'react';
import { User } from '../../types/types';
import { sendCustomNotification } from '../../services/api';
import LoadingButton from '../common/LoadingButton';
import Notification from '../common/Notification';

interface NotificationSenderProps {
  users: User[];
}

const NotificationSender: React.FC<NotificationSenderProps> = ({ users }) => {
  const [message, setMessage] = useState('');
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState({ message: '', type: 'success' as 'success' | 'error' });

  const handleSend = async () => {
    if (!message) {
      setNotification({ message: 'Please enter a message', type: 'error' });
      return;
    }

    setIsLoading(true);
    try {
      const response = await sendCustomNotification(selectedUser, message);
      setNotification({ message: 'Notification sent successfully', type: 'success' });
      setMessage('');
      setSelectedUser(null);
    } catch (error) {
      console.error('Error sending notification:', error);
      setNotification({ message: 'Failed to send notification: ' + (error instanceof Error ? error.message : String(error)), type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white shadow sm:rounded-lg p-6">
      <h2 className="text-lg font-medium text-gray-900 mb-4">Send Custom Notification</h2>
      <div className="space-y-4">
        <div>
          <label htmlFor="user" className="block text-sm font-medium text-gray-700">
            Select User (optional)
          </label>
          <select
            id="user"
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            value={selectedUser || ''}
            onChange={(e) => setSelectedUser(e.target.value || null)}
          >
            <option value="">All Users</option>
            {users.map((user) => (
              <option key={`${user.deviceUDID}-${user.username}`} value={user.deviceUDID}>
                {user.username} ({user.deviceUDID})
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="message" className="block text-sm font-medium text-gray-700">
            Message
          </label>
          <textarea
            id="message"
            rows={3}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <LoadingButton
          onClick={handleSend}
          isLoading={isLoading}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Send Notification
        </LoadingButton>
      </div>
      {notification.message && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification({ message: '', type: 'success' })}
        />
      )}
    </div>
  );
};

export default NotificationSender;