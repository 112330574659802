// Fixtures.tsx

import React, { useState, useEffect, useMemo } from 'react';
import { fetchGameweeks, updateGameweek, getGameweekLeaderboard, notifyUsersOfFixtureChanges } from '../services/api';
import GameweekCard from '../components/fixtures/GameweekCard';
import Drawer from '../components/common/Drawer';
import Notification from '../components/common/Notification';
import { useAuth } from '../contexts/AuthContext';
import { getErrorMessage } from '../utils/errorHandling';
import { Gameweek, LeaderboardEntry, Fixture, Leaderboard } from '../types/types';

const Fixtures: React.FC = () => {
  const [gameweeks, setGameweeks] = useState<Gameweek[]>([]);
  const [activeTab, setActiveTab] = useState('upcoming');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [notification, setNotification] = useState({ message: '', type: 'success' });
  const [leaderboardData, setLeaderboardData] = useState<Leaderboard | null>(null);
  const [userCounts, setUserCounts] = useState<Record<string, number>>({});
  const { user } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchGameweeks(true);
        setGameweeks(response.data.gameweeks);
      } catch (error) {
        console.error('Error fetching gameweeks:', error);
        setError('Failed to load gameweeks');
        setNotification({ message: 'Failed to load gameweeks', type: 'error' });
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [user]);

  const handleUpdateGameweek = async (updatedGameweek: Gameweek) => {
    try {
      const { data } = await updateGameweek(updatedGameweek._id, {
        selectedFixtures: updatedGameweek.selectedFixtures,
        tokenRewards: updatedGameweek.tokenRewards,
        isFeatured: updatedGameweek.isFeatured,
        videoUrl: updatedGameweek.videoUrl,
        prize: updatedGameweek.prize
      });
      
      if (data.gameweek) {
        setGameweeks(prevGameweeks =>
          prevGameweeks.map(gw =>
            gw._id === data.gameweek._id ? { ...gw, ...data.gameweek } : gw
          )
        );
        
        setNotification({ message: data.message, type: 'success' });
      } else {
        setNotification({ message: 'Gameweek updated, but no changes were returned', type: 'warning' });
      }
    } catch (error) {
      console.error('Failed to update gameweek:', error);
      setNotification({ message: getErrorMessage(error) || 'Failed to update gameweek', type: 'error' });
    }
  };

  const fetchLeaderboardData = async (gameweekId: string) => {
    try {
      const response = await getGameweekLeaderboard(gameweekId);
      if (response && Array.isArray(response.entries) && response.entries.length > 0) {
        setLeaderboardData(response);
        setIsDrawerOpen(true);
      } else {
        setNotification({ message: 'No users for this gameweek or invalid data format', type: 'info' });
      }
    } catch (error) {
      console.error('Error fetching leaderboard data:', error);
      setNotification({ message: 'Failed to fetch leaderboard data', type: 'error' });
    }
  };

  const filteredGameweeks = useMemo(() => {
    const currentDate = new Date();
    return gameweeks.filter(gameweek => {
      if (activeTab === 'upcoming') {
        return !gameweek.isFinished;
      } else {
        return gameweek.isFinished;
      }
    });
  }, [gameweeks, activeTab]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-2xl font-semibold text-gray-900 mb-6">Fixtures</h1>
      <div className="mt-4">
        <div className="sm:hidden">
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            value={activeTab}
            onChange={(e) => setActiveTab(e.target.value)}
          >
            <option value="upcoming">Upcoming</option>
            <option value="finished">Finished</option>
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="flex space-x-4" aria-label="Tabs">
            <button
              onClick={() => setActiveTab('upcoming')}
              className={`${
                activeTab === 'upcoming'
                  ? 'bg-indigo-100 text-indigo-700'
                  : 'text-gray-500 hover:text-gray-700'
              } px-3 py-2 font-medium text-sm rounded-md`}
            >
              Upcoming
            </button>
            <button
              onClick={() => setActiveTab('finished')}
              className={`${
                activeTab === 'finished'
                  ? 'bg-indigo-100 text-indigo-700'
                  : 'text-gray-500 hover:text-gray-700'
              } px-3 py-2 font-medium text-sm rounded-md`}
            >
              Finished
            </button>
          </nav>
        </div>
      </div>
      <div className="mt-6 space-y-6">
        {filteredGameweeks.map((gameweek) => (
          <GameweekCard 
            key={gameweek._id} 
            gameweek={gameweek} 
            onUpdate={handleUpdateGameweek}
            userCount={userCounts[gameweek._id] || 0}
            onViewLeaderboard={fetchLeaderboardData}
            canEdit={!gameweek.isFinished && new Date(gameweek.startDate) > new Date()}
            canViewLeaderboard={gameweek.isActive || gameweek.isFinished}
          />
        ))}
      </div>

      <Drawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        title="Leaderboard"
      >
        {leaderboardData && leaderboardData.entries && leaderboardData.entries.length > 0 ? (
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th>Position</th>
                <th>UUID</th>
                <th>Username</th>
                <th>Points</th>
              </tr>
            </thead>
            <tbody>
              {leaderboardData.entries.map((user, index) => (
                <tr key={user.deviceUDID}>
                  <td>{index + 1}</td>
                  <td>{user.deviceUDID}</td>
                  <td>{user.username}</td>
                  <td>{user.totalPoints}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No leaderboard data available.</p>
        )}
      </Drawer>

      {notification.message && (
        <Notification
          message={notification.message}
          type={notification.type as 'success' | 'error'}
          onClose={() => setNotification({ message: '', type: 'success' })}
        />
      )}
    </div>
  );
};

export default Fixtures;