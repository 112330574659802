import React, { useState, useEffect } from 'react';
import { getReferralSettings, updateReferralSettings, getAdminReferralStats } from '../services/api';
import ReferralSettings from '../components/referrals/ReferralSettings';
import Notification from '../components/common/Notification';
import { useAuth } from '../contexts/AuthContext';

interface ReferralSettingsData {
  referrerReward: number;
  referredReward: number;
  requiredGameweeks: number;
  appStoreLink: string;
}

interface ReferralStats {
  totalReferrals: number;
  successfulReferrals: number;
  pendingReferrals: number;
}

const Referrals: React.FC = () => {
  const [settings, setSettings] = useState<ReferralSettingsData | null>(null);
  const [stats, setStats] = useState<ReferralStats | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [notification, setNotification] = useState({ message: '', type: 'success' });
  const { user } = useAuth();

  const loadData = async () => {
    if (!user) return;
    setLoading(true);
    try {
      const [settingsResponse, statsResponse] = await Promise.all([
        getReferralSettings(),
        getAdminReferralStats()
      ]);
      setSettings(settingsResponse.data);
      setStats(statsResponse.data);
    } catch (err) {
      console.error('Error fetching referral data:', err);
      setError('Failed to load referral data');
      setNotification({ message: 'Failed to load referral data', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [user]);

  const handleSave = async (newSettings: ReferralSettingsData) => {
    try {
      await updateReferralSettings(newSettings);
      setSettings(newSettings);
      setNotification({ message: 'Referral settings updated successfully', type: 'success' });
      loadData(); // Reload data after saving
    } catch (err) {
      console.error('Error updating referral settings:', err);
      setError('Failed to update referral settings');
      setNotification({ message: 'Failed to update referral settings', type: 'error' });
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h1 className="text-2xl font-semibold text-gray-900 mb-6">Referrals</h1>
      
      {stats && (
        <div className="mb-8">
          <h2 className="text-xl font-semibold text-gray-900 mb-4">Referral Statistics</h2>
          <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
            <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
              <dt className="text-sm font-medium text-gray-500 truncate">Total Referrals</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{stats.totalReferrals}</dd>
            </div>
            <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
              <dt className="text-sm font-medium text-gray-500 truncate">Successful Referrals</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{stats.successfulReferrals}</dd>
            </div>
            <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
              <dt className="text-sm font-medium text-gray-500 truncate">Pending Referrals</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{stats.pendingReferrals}</dd>
            </div>
          </dl>
        </div>
      )}

      <div className="mt-8">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">Referral Settings</h2>
        {settings && (
          <ReferralSettings 
            settings={settings} 
            onSave={handleSave}
          />
        )}
      </div>

      {notification.message && (
        <Notification
          message={notification.message}
          type={notification.type as 'success' | 'error'}
          onClose={() => setNotification({ message: '', type: 'success' })}
        />
      )}
    </div>
  );
};

export default Referrals;