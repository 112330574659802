import React, { useEffect, useState } from 'react';
import { getDashboardStats, getRetentionRates } from '../services/api';
import { AxiosResponse } from 'axios';
import { DashboardStats, RetentionRates, RetentionRatesResponse } from '../types/types';
import Alert from '../components/common/Alert';

const Dashboard: React.FC = () => {
  const [stats, setStats] = useState<DashboardStats | null>(null);
  const [retentionRates, setRetentionRates] = useState<RetentionRates | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const { data: dashboardStats } = await getDashboardStats();
        setStats(dashboardStats);
  
        const { data: response } = await getRetentionRates();
        if (response.success) {
          setRetentionRates(response.data);
          if (response.message === "No users found in the database") {
            setError("No user data available to calculate retention rates.");
          } else {
            setError(null);
          }
        } else {
          setError(response.error || "Failed to fetch retention rates");
          setRetentionRates(response.data); // Use the default values
        }
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        setError("Failed to fetch dashboard data");
      }
    };
  
    fetchDashboardData();
  }, []);

  if (!stats || !retentionRates) {
    return <div>Loading...</div>;
  }

  const userStats = [
    { name: 'Total Users', stat: stats.totalUsers.toLocaleString() },
    { name: 'Gameweeks Picked', stat: stats.gameweeksPicked.toLocaleString() },
    { name: 'New Users (24h)', stat: stats.newUsers24h.toLocaleString() },
  ];

  const retentionStats = [
    { name: '1D Retention', stat: `${retentionRates['1D'].toFixed(2)}%` },
    { name: '14D Retention', stat: `${retentionRates['14D'].toFixed(2)}%` },
    { name: '30D Retention', stat: `${retentionRates['30D'].toFixed(2)}%` },
  ];

  return (
    <div>
      {error && (
        <Alert
          type="error"
          title="Error"
          message={error}
          onDismiss={() => setError(null)}
        />
      )}
      <h3 className="text-base font-semibold leading-6 text-gray-900">User Statistics</h3>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        {userStats.map((item) => (
          <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
          </div>
        ))}
      </dl>
      <h3 className="mt-10 text-base font-semibold leading-6 text-gray-900">Retention Rates</h3>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        {retentionStats.map((item) => (
          <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

export default Dashboard;