import React, { useState, useEffect, useContext } from 'react';
import { fetchNotificationTemplates, updateNotificationTemplate, fetchUsers } from '../services/api';
import NotificationTemplate from '../components/notifications/NotificationTemplate';
import LoadingButton from '../components/common/LoadingButton';
import Notification from '../components/common/Notification';
import { NotificationContext, NotificationType } from '../App';
import { useAuth } from '../contexts/AuthContext';
import NotificationSender from '../components/notifications/NotificationSender';
import { User } from '../types/types'; // Add this import

interface Template {
  name: string;
  content: string;
  variables?: string[]; // Make variables optional
}

const Notifications: React.FC = () => {
  const [templates, setTemplates] = useState<Template[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [users, setUsers] = useState<User[]>([]); // New state for users
  const notificationContext = useContext(NotificationContext);
  const { user } = useAuth();

  useEffect(() => {
    const loadTemplates = async () => {
      if (!user) return;
      setLoading(true);
      try {
        const fetchedTemplates = await fetchNotificationTemplates();
        setTemplates(fetchedTemplates.data.map(t => ({
          name: t.name,
          content: t.content,
          variables: t.variables
        })));
      } catch (err) {
        console.error('Error fetching notification templates:', err);
        setError('Failed to load notification templates');
        notificationContext?.setNotification({ message: 'Failed to load notification templates', type: 'error' });
      } finally {
        setLoading(false);
      }
    };
    loadTemplates();
  }, [user, notificationContext]);

  useEffect(() => {
    const loadUsers = async () => {
      try {
        const response = await fetchUsers();
        setUsers(response.data.users);
      } catch (err) {
        console.error('Error fetching users:', err);
        notificationContext?.setNotification({ message: 'Failed to load users', type: 'error' });
      }
    };
    loadUsers();
  }, [user, notificationContext]);

  const handleSave = async (name: string, content: string) => {
    try {
      await updateNotificationTemplate(name, content);
      setTemplates(templates.map(t => t.name === name ? { ...t, content } : t));
      notificationContext?.setNotification({ message: 'Template updated successfully', type: 'success' });
    } catch (err) {
      console.error('Error updating template:', err);
      notificationContext?.setNotification({ message: 'Failed to update template', type: 'error' });
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <h1 className="text-2xl font-semibold text-gray-900">Notification Templates</h1>
      <div className="mt-6 space-y-6">
        {templates.map((template) => (
          <NotificationTemplate
            key={template.name}
            name={template.name}
            content={template.content}
            variables={template.variables}
            onSave={handleSave}
          />
        ))}
      </div>
      <LoadingButton
        isLoading={loading}
        onClick={() => handleSave('all', 'all')}
        className="mt-4"
      >
        Save All
      </LoadingButton>
      
      {/* Add NotificationSender component */}
      <div className="mt-8">
        <NotificationSender users={users} />
      </div>
    </div>
  );
};

export default Notifications;