// matchday6-admin/src/services/api.ts

import axios, { AxiosResponse } from 'axios';
import {
  User,
  Gameweek,
  UserPick,
  Leaderboard,
  Winner,
  Season,
  StoreItem,
  Redemption,
  GlobalLeaderboard,
  HistoricalLeaderboard,
  Player,
  DashboardStats,
  RetentionRates,
  ReferralSettings,
  ReferralStats,
  PushNotificationTemplate,
  PushNotificationSettings,
  TokenHistory,
  LeaderboardEntry,
  GameweekUpdateResponse,
  UserPickData,
  MonthlyLeaderboard
} from '../types/types';
// Remove import of getToken if it's causing issues
// import { getToken } from './auth';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://matchday6.app/api';

// Create a single Axios instance
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Set up Axios interceptor to add token to headers
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Now, use 'api' for all API calls

// Export get and post functions
export const get = api.get;
export const post = api.post;
export const put = api.put;
export const del = api.delete;

// Auth
export const loginUser = async (email: string, password: string) => {
  try {
    const response = await api.post('/admin/login', { email, password });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || 'An error occurred during login');
    }
    throw new Error('An unexpected error occurred during login');
  }
};

export const getCurrentUser = async () => {
  try {
    const response = await api.get('/admin/current-user');
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch current user');
  }
};

// Users
export const fetchUsers = (page: number = 1, perPage: number = 50) =>
  api.get<{ users: User[]; total: number; page: number; per_page: number }>(
    `/admin/users?page=${page}&per_page=${perPage}`
  );

export const getUserDetails = (deviceUDID: string) =>
  api.get<User>(`/users/${deviceUDID}`);

export const deleteUser = (deviceUDID: string) =>
  api.delete(`/users/${deviceUDID}`);

// Gameweeks
export const fetchGameweeks = (isAdmin: boolean = false): Promise<AxiosResponse<{ gameweeks: Gameweek[] }>> =>
  get<{ gameweeks: Gameweek[] }>(`/gameweeks?admin=${isAdmin}`);

export const getGameweek = (gameweekId: string) =>
  get<Gameweek>(`/gameweeks/${gameweekId}`);

export const updateGameweek = (gameweekId: string, data: Partial<Gameweek>): Promise<AxiosResponse<GameweekUpdateResponse>> =>
  put<GameweekUpdateResponse>(`/gameweeks/${gameweekId}`, data);

export const getActiveGameweek = () =>
  get<Gameweek>('/gameweeks/active');

// User Picks
export const submitPicks = (data: UserPick) =>
  post<UserPick>('/picks', data);

export const getInvalidPicks = () =>
  get<UserPick[]>('/picks/invalid');

export const updateInvalidPicks = (deviceUDID: string, gameweekId: string, invalidPicks: any[]) =>
  post<{ message: string }>(`/picks/${deviceUDID}/${gameweekId}/update-invalid`, { invalidPicks });

// Leaderboards
export const getLeaderboard = (gameweekId: string) =>
  get<Leaderboard>(`/leaderboard/${gameweekId}`);

export const getGlobalLeaderboard = () =>
  get<GlobalLeaderboard>('/leaderboard/global');

export const getGlobalLeaderboardBySeason = (season: string) =>
  get<GlobalLeaderboard>(`/leaderboard/global/${season}`);

export const getGlobalCorrectPicksLeaderboard = () =>
  get<{ rank: number; deviceUDID: string; username: string; correctPicks: number }[]>('/leaderboard/global/correct-picks');

export const getGlobalPerfectGameweeksLeaderboard = () =>
  get<{ rank: number; deviceUDID: string; username: string; perfectGameweeks: number }[]>('/leaderboard/global/perfect-gameweeks');

// Add this near the other leaderboard-related functions
export const getGameweekLeaderboard = (gameweekId: string): Promise<Leaderboard> =>
  get<Leaderboard>(`/leaderboard/${gameweekId}`).then(response => response.data);

// Winners
export const fetchWinners = () =>
  get<Winner[]>('/admin/winners');

export const checkWinner = (gameweekId: string) =>
  get<{ isWinner: boolean; claimed: boolean; claimEmail?: string; winnerDeviceUDID?: string }>(`/check-winner/${gameweekId}`);

export const updateWinnerClaimStatus = (winnerDeviceUDID: string, claimed: boolean) =>
  put<{ message: string; winner: Winner }>(`/winners/${winnerDeviceUDID}/claim`, { claimed });

// Store
export const fetchStoreItems = (): Promise<StoreItem[]> =>
  get<StoreItem[]>('/store/items').then(response => response.data);

export const createStoreItem = (item: Omit<StoreItem, '_id'>) =>
  post<{ message: string; item: StoreItem }>('/store/items', item);

export const updateStoreItem = (itemId: string, item: Partial<Omit<StoreItem, '_id'>>) =>
  put<{ message: string }>(`/store/items/${itemId}`, item);

export const deleteStoreItem = (itemId: string) =>
  del<{ message: string }>(`/store/items/${itemId}`);

export const redeemItem = (itemId: string, address?: string, email?: string) =>
  post<{ message: string }>('/store/redeem', { itemId, address, email });

// User Tokens
export const getUserTokens = () =>
  get<{ tokens: number }>('/user/tokens');

export const getUserTokensAndReferral = () =>
  get<{ tokens: number; referralCode: string; referralLink: string; qrCode: string }>('/user/tokens-and-referral');

export const getUserTokenHistory = () =>
  get<TokenHistory[]>('/user/token-history');

// Referrals
export const processReferral = (referrerId: string, referredId: string) =>
  post<{ message: string }>('/referral/process', { referrerId, referredId });

export const getReferralQRCode = () =>
  get<{ qrCode: string }>('/user/referral-qr');

export const getUserReferralStats = () =>
  get<{ referralCode: string; referralLink: string; referredUsers: number }>('/user/referral-stats');

export const validateReferralCode = (code: string) =>
  post<{ isValid: boolean }>('/referral/validate', { referralCode: code });

// Admin
export const getDashboardStats = () =>
  get<DashboardStats>('/admin/dashboard-stats');

export const getRetentionRates = (): Promise<AxiosResponse<{ success: boolean, data: RetentionRates, message?: string, error?: string }>> =>
  get<{ success: boolean, data: RetentionRates, message?: string, error?: string }>('/admin/retention-rates');

export const getUserReferrals = (deviceUDID: string) =>
  get<{ user: User; referredUsers: User[] }>(`/admin/users/${deviceUDID}/referred`);

export const getAdminReferralStats = () =>
  get<ReferralStats>('/admin/referral-stats');

export const getSalesByDateRange = (startDate: string, endDate: string) =>
  get<{ [date: string]: { totalSales: number; itemsSold: number } }>(`/admin/sales?start_date=${startDate}&end_date=${endDate}`);

export const fetchRedemptions = async (): Promise<Redemption[]> => {
  const response = await get<Redemption[]>('/admin/redemptions');
  return response.data;
};

export const updateRedemptionStatus = (redemptionId: string, status: string): Promise<AxiosResponse<{ message: string }>> =>
  put<{ message: string }>(`/admin/redemptions/${redemptionId}`, { status });

export const getReferralSettings = () =>
  get<ReferralSettings>('/admin/referral-settings');

export const updateReferralSettings = (settings: ReferralSettings) =>
  put<{ message: string }>('/admin/referral-settings', settings);

// Notifications
export const getFixtureChangeNotifications = () =>
  get<any[]>('/notifications/fixture-changes');

export const getPushNotificationSettings = () =>
  get<PushNotificationSettings>('/push-notifications/settings');

export const updatePushNotificationSettings = (settings: PushNotificationSettings) =>
  put<{ message: string }>('/push-notifications/settings', settings);

export const updateDeviceToken = (deviceToken: string) =>
  post<{ message: string }>('/push-notifications/device-token', { deviceToken });

export const getPushNotificationTemplates = () =>
  get<PushNotificationTemplate[]>('/push-notifications/templates');

export const updateNotificationTemplate = (templateName: string, content: string): Promise<AxiosResponse<{ message: string }>> =>
  put<{ message: string }>(`/push-notifications/templates/${templateName}`, { content });

export const fetchNotificationTemplates = () =>
  get<PushNotificationTemplate[]>('/push-notifications/templates');

// Fixtures
export const getHighestScoringPlayer = (fixtureId: string) =>
  get<Player>(`/fixtures/${fixtureId}/highest-scorer`);

// Seasons
export const getCurrentSeason = () =>
  get<{ season: string }>('/current-season');

// New function
export const notifyUsersOfFixtureChanges = (gameweekId: string) =>
  post<{ message: string }>(`/admin/gameweeks/${gameweekId}/notify-fixture-changes`);

// Add this new function
export const sendCustomNotification = async (deviceUDID: string | null, message: string) => {
  try {
    const response = await post<{ success: boolean; message: string }>('/admin/send-custom-notification', { deviceUDID, message });
    return response.data;
  } catch (error) {
    console.error('Error sending custom notification:', error);
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || 'Failed to send notification');
    }
    throw new Error('An unexpected error occurred while sending the notification');
  }
};

export const getUserPicks = (deviceUDID: string) =>
  api.get<UserPickData[]>(`/users/${deviceUDID}/picks`);

export const getMonthlyLeaderboards = (season: string) => {
  return api.get(`/admin/monthly-leaderboards/${season}`);
};

export const getMonthlyLeaderboardDetails = (month: string, season: string) => {
  return api.get(`/admin/monthly-leaderboard/${month}/${season}`);
};

export const setMonthlyLeaderboardPrize = (month: string, prize: string) => {
  return api.post(`/admin/monthly-leaderboard/${month}/prize`, { prize });
};

export const getAllSeasons = (): Promise<AxiosResponse<Season[]>> => {
  return api.get<Season[]>('/admin/seasons');
};