// UserDetail.tsx

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getUserDetails, deleteUser, getUserPicks } from '../services/api';
import Notification from '../components/common/Notification';
import LoadingButton from '../components/common/LoadingButton';
import {
  CalendarDaysIcon,
  MapIcon,
  QrCodeIcon,
  EnvelopeIcon,
} from '@heroicons/react/20/solid';
import { UserPickData } from '../types/types';

const UserDetail: React.FC = () => {
  const { deviceUDID } = useParams<{ deviceUDID: string }>();
  const [user, setUser] = useState<any>(null);
  const [userPicks, setUserPicks] = useState<UserPickData[]>([]);
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [notification, setNotification] = useState<{ message: string; type: 'success' | 'error' }>({
    message: '',
    type: 'success',
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [userResponse, picksResponse] = await Promise.all([
          getUserDetails(deviceUDID!),
          getUserPicks(deviceUDID!),
        ]);
        setUser(userResponse.data);
        setUserPicks(picksResponse.data);
      } catch (error) {
        console.error('Error fetching user details or picks:', error);
        setNotification({ message: 'Failed to load user details', type: 'error' });
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [deviceUDID]);

  const handleDelete = async () => {
    if (
      !window.confirm(
        'Are you sure you want to delete this user? This action cannot be undone.'
      )
    )
      return;
    setDeleting(true);
    try {
      await deleteUser(deviceUDID!);
      setNotification({ message: 'User deleted successfully', type: 'success' });
      navigate('/users');
    } catch (error) {
      console.error('Error deleting user:', error);
      setNotification({ message: 'Failed to delete user', type: 'error' });
    } finally {
      setDeleting(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (!user) return <div>User not found</div>;

  // Format dates
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  };

  return (
    <div className="p-4">
      {/* Header with Delete Button */}
      <div className="bg-white rounded-lg shadow p-4 flex items-center justify-between">
        {/* User Info */}
        <div className="flex items-center">
          <div className="h-16 w-16 rounded-full bg-gray-200 flex items-center justify-center">
            <span className="text-2xl font-medium text-gray-700">
              {user.username.slice(0, 2).toUpperCase()}
            </span>
          </div>
          <div className="ml-4">
            <h1 className="text-2xl font-semibold">{user.username}</h1>
            <p className="text-sm text-gray-500">{user.deviceUDID}</p>
          </div>
        </div>
        {/* Delete Button */}
        <LoadingButton
          onClick={handleDelete}
          isLoading={deleting}
          className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
        >
          Delete User
        </LoadingButton>
      </div>

      <div className="mt-8 grid grid-cols-1 lg:grid-cols-3 lg:gap-8">
        {/* Left Column */}
        <div className="lg:col-span-2">
          <div className="rounded-lg bg-white shadow-sm ring-1 ring-gray-900/5 p-6">
            <ul role="list" className="divide-y divide-gray-100">
              {userPicks.map((pick) => (
                <li
                  key={pick.gameweekNumber}
                  className="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 py-5 sm:flex-nowrap"
                >
                  <div>
                    {/* Title */}
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      Joined Gameweek {pick.gameweekNumber}
                    </p>
                    {/* Subtitle */}
                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                      <p>{pick.numPicks} Picks Made</p>
                    </div>
                  </div>
                  {/* Player Images with Tooltip */}
                  <div className="flex -space-x-0.5">
                    {pick.playerImages.map((player) => (
                      <div key={player.id} className="relative group">
                        <img
                          alt={player.name}
                          src={player.imageUrl}
                          className="h-6 w-6 rounded-full bg-gray-50 ring-2 ring-white"
                        />
                        <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 hidden group-hover:block bg-gray-700 text-white text-xs rounded py-1 px-2">
                          <p>{player.name}</p>
                          <p>{player.fixture}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Right Column */}
        <div className="lg:col-span-1">
          <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5 pb-6">
            <dl className="flex flex-wrap">
              {/* Last Login */}
              <div className="flex-auto pl-6 pt-6">
                <dt className="text-sm font-semibold leading-6 text-gray-900">Last Login</dt>
                <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">
                  {user.lastLoginDate ? formatDate(user.lastLoginDate) : 'N/A'}
                </dd>
              </div>
              {/* Referral Code */}
              <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                <dt className="flex-none">
                  <span className="sr-only">Referral Code</span>
                  <QrCodeIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                </dt>
                <dd className="text-sm font-medium leading-6 text-gray-900">
                  {user.referralCode || 'N/A'}
                </dd>
              </div>
              {/* Sign Up Date */}
              <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                <dt className="flex-none">
                  <span className="sr-only">Sign Up Date</span>
                  <CalendarDaysIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                </dt>
                <dd className="text-sm leading-6 text-gray-500">
                  {user.signUpDate ? formatDate(user.signUpDate) : 'N/A'}
                </dd>
              </div>
              {/* Email Address */}
              {user.email && (
                <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                  <dt className="flex-none">
                    <span className="sr-only">Email Address</span>
                    <EnvelopeIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                  </dt>
                  <dd className="text-sm leading-6 text-gray-500">
                    {user.email}
                  </dd>
                </div>
              )}
              {/* Address */}
              {user.address && (
                <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                  <dt className="flex-none">
                    <span className="sr-only">Address</span>
                    <MapIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                  </dt>
                  <dd className="text-sm leading-6 text-gray-500">
                    {[
                      user.address.addressLine1,
                      user.address.addressLine2,
                      user.address.county,
                      user.address.postCode,
                      user.address.country,
                    ]
                      .filter(Boolean)
                      .join(', ')}
                  </dd>
                </div>
              )}
            </dl>
          </div>
        </div>
      </div>

      {notification.message && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification({ message: '', type: 'success' })}
        />
      )}
    </div>
  );
};

export default UserDetail;