// SeasonSelector.tsx
import React, { useEffect, useState } from 'react';
import { getAllSeasons } from '../../services/api';
import { Season } from '../../types/types';

interface SeasonSelectorProps {
  season: string;
  onSeasonChange: (season: string) => void;
}

const SeasonSelector: React.FC<SeasonSelectorProps> = ({ season, onSeasonChange }) => {
  const [seasons, setSeasons] = useState<Season[]>([]);

  useEffect(() => {
    const fetchSeasons = async () => {
      try {
        const response = await getAllSeasons();
        setSeasons(response.data);
      } catch (error) {
        console.error('Error fetching seasons:', error);
      }
    };
    fetchSeasons();
  }, []);

  return (
    <div className="season-selector">
      <label htmlFor="season-select">Season:</label>
      <select
        id="season-select"
        value={season}
        onChange={(e) => onSeasonChange(e.target.value)}
        className="form-select"
      >
        {seasons.map((s) => (
          <option key={s.season} value={s.season}>
            {s.season}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SeasonSelector;