import React, { useState, useEffect } from 'react';
import { Gameweek } from '../../types/types';
import Notification from '../common/Notification';
import FixtureTable from './FixtureTable';
import LoadingButton from '../common/LoadingButton';
import { updateGameweek } from '../../services/api';
import { getErrorMessage } from '../../utils/errorHandling';
import { classNames } from '../../utils/classNames';
import { isEqual } from 'lodash';

type NotificationType = 'success' | 'error' | 'info';

interface NotificationState {
  message: string;
  type: NotificationType;
}

interface GameweekCardProps {
  gameweek: Gameweek;
  onUpdate: (gameweek: Gameweek) => void;
  userCount: number;
  onViewLeaderboard: (gameweekId: string) => void;
  canEdit: boolean;
  canViewLeaderboard: boolean;
}

const GameweekCard: React.FC<GameweekCardProps> = ({ gameweek, onUpdate, userCount, onViewLeaderboard, canEdit, canViewLeaderboard }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedGameweek, setEditedGameweek] = useState<Gameweek>(gameweek);
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState<NotificationState>({ message: '', type: 'success' });

  useEffect(() => {
    setEditedGameweek(gameweek);
  }, [gameweek]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedGameweek(gameweek);
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const updatedData: Partial<Gameweek> = {};
      
      if (!isEqual(editedGameweek.selectedFixtures, gameweek.selectedFixtures)) {
        updatedData.selectedFixtures = editedGameweek.selectedFixtures;
      }
      if (!isEqual(editedGameweek.tokenRewards, gameweek.tokenRewards)) {
        updatedData.tokenRewards = editedGameweek.tokenRewards;
      }
      if (editedGameweek.isFeatured !== gameweek.isFeatured) {
        updatedData.isFeatured = editedGameweek.isFeatured;
      }
      if (editedGameweek.videoUrl !== gameweek.videoUrl) {
        updatedData.videoUrl = editedGameweek.videoUrl;
      }
      if (editedGameweek.prize !== gameweek.prize) {
        updatedData.prize = editedGameweek.prize;
      }

      if (Object.keys(updatedData).length === 0) {
        setNotification({ message: 'No changes were made to the gameweek', type: 'info' });
        setIsEditing(false);
        setIsLoading(false);
        return;
      }

      const response = await updateGameweek(gameweek._id, updatedData);
      
      if (response.data && response.data.gameweek) {
        onUpdate(response.data.gameweek);
        setEditedGameweek(response.data.gameweek);
        setNotification({ message: response.data.message, type: 'success' });
        setIsEditing(false);
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error updating gameweek:', error);
      setNotification({ message: getErrorMessage(error) || 'Failed to update gameweek', type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFixtureSelect = (fixtureId: string) => {
    setEditedGameweek((prev) => {
      const newSelectedFixtures = prev.selectedFixtures.includes(fixtureId)
        ? prev.selectedFixtures.filter((id) => id !== fixtureId)
        : prev.selectedFixtures.length < 6
          ? [...prev.selectedFixtures, fixtureId]
          : prev.selectedFixtures;

      return { ...prev, selectedFixtures: newSelectedFixtures };
    });
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-8">
      <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Gameweek {gameweek.gameweekNumber}
          {!canEdit && (
            <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
              Locked
            </span>
          )}
          <span className="ml-2 text-sm text-gray-500">Users: {userCount}</span>
        </h3>
        <div className="flex space-x-2">
          {canEdit && !isEditing ? (
            <button
              onClick={handleEdit}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Edit
            </button>
          ) : null}
          {canViewLeaderboard && (
            <button
              onClick={() => onViewLeaderboard(gameweek._id)}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              View Leaderboard
            </button>
          )}
          {isEditing ? (
            <>
              <button
                onClick={handleCancel}
                className={classNames(
                  "inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700",
                  "bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                )}
              >
                Cancel
              </button>
              <LoadingButton
                onClick={handleSave}
                isLoading={isLoading}
                className={classNames(
                  "inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white",
                  "bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                )}
              >
                Save
              </LoadingButton>
            </>
          ) : null}
        </div>
      </div>
      <FixtureTable 
        fixtures={gameweek.fixtures} 
        selectedFixtures={isEditing ? editedGameweek.selectedFixtures : gameweek.selectedFixtures}
        isEditing={isEditing && canEdit}
        fixturesChanged={gameweek.fixturesChanged}
        onFixtureSelect={handleFixtureSelect}
      />
      {isEditing && (
        <div className="px-4 py-5 sm:p-6">
          <h4 className="text-lg font-medium text-gray-900 mb-4">Token Rewards</h4>
          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-7">
            {['join', 'correct1', 'correct2', 'correct3', 'correct4', 'correct5', 'correct6'].map((key) => (
              <div key={key} className="sm:col-span-1">
                <label htmlFor={key} className="block text-sm font-medium text-gray-700">
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </label>
                <div className="mt-1">
                  <input
                    type="number"
                    name={`tokenRewards.${key}`}
                    id={key}
                    value={editedGameweek.tokenRewards[key as keyof typeof editedGameweek.tokenRewards]}
                    onChange={(e) => {
                      const { name, value, type, checked } = e.target;
                      setEditedGameweek((prev) => {
                        if (name.startsWith('tokenRewards.')) {
                          const [, key] = name.split('.');
                          return {
                            ...prev,
                            tokenRewards: {
                              ...prev.tokenRewards,
                              [key]: type === 'number' ? Number(value) : value,
                            },
                          };
                        }
                        return {
                          ...prev,
                          [name]: type === 'checkbox' ? checked : value,
                        };
                      });
                    }}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            ))}
          </div>
          <h4 className="text-lg font-medium text-gray-900 mt-6 mb-4">Configurables</h4>
          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="prize" className="block text-sm font-medium text-gray-700">
                Prize
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="prize"
                  id="prize"
                  value={editedGameweek.prize}
                  onChange={(e) => {
                    const { name, value, type, checked } = e.target;
                    setEditedGameweek((prev) => {
                      if (name.startsWith('tokenRewards.')) {
                        const [, key] = name.split('.');
                        return {
                          ...prev,
                          tokenRewards: {
                            ...prev.tokenRewards,
                            [key]: type === 'number' ? Number(value) : value,
                          },
                        };
                      }
                      return {
                        ...prev,
                        [name]: type === 'checkbox' ? checked : value,
                      };
                    });
                  }}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="videoUrl" className="block text-sm font-medium text-gray-700">
                Video URL
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="videoUrl"
                  id="videoUrl"
                  value={editedGameweek.videoUrl}
                  onChange={(e) => {
                    const { name, value, type, checked } = e.target;
                    setEditedGameweek((prev) => {
                      if (name.startsWith('tokenRewards.')) {
                        const [, key] = name.split('.');
                        return {
                          ...prev,
                          tokenRewards: {
                            ...prev.tokenRewards,
                            [key]: type === 'number' ? Number(value) : value,
                          },
                        };
                      }
                      return {
                        ...prev,
                        [name]: type === 'checkbox' ? checked : value,
                      };
                    });
                  }}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="sm:col-span-6">
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="isFeatured"
                    name="isFeatured"
                    type="checkbox"
                    checked={editedGameweek.isFeatured}
                    onChange={(e) => {
                      const { name, value, type, checked } = e.target;
                      setEditedGameweek((prev) => {
                        if (name.startsWith('tokenRewards.')) {
                          const [, key] = name.split('.');
                          return {
                            ...prev,
                            tokenRewards: {
                              ...prev.tokenRewards,
                              [key]: type === 'number' ? Number(value) : value,
                            },
                          };
                        }
                        return {
                          ...prev,
                          [name]: type === 'checkbox' ? checked : value,
                        };
                      });
                    }}
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="isFeatured" className="font-medium text-gray-700">
                    Featured
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {notification.message && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification({ message: '', type: 'success' })}
        />
      )}
    </div>
  );
};

export default GameweekCard;