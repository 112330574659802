import React, { useState } from 'react';
import LoadingButton from '../common/LoadingButton';
import Notification from '../common/Notification';
import { Winner } from '../../types/types';

interface WinnersTableProps {
  winners: Winner[];
  onUpdateClaimStatus: (winnerUuid: string, claimed: boolean) => Promise<void>;
  isUpdating: boolean;
}

const WinnersTable: React.FC<WinnersTableProps> = ({ winners, onUpdateClaimStatus, isUpdating }) => {
  const [loadingWinners, setLoadingWinners] = useState<{ [key: string]: boolean }>({});
  const [notification, setNotification] = useState({ message: '', type: 'success' });

  const handleClaimUpdate = async (winnerUuid: string, claimed: boolean) => {
    setLoadingWinners(prev => ({ ...prev, [winnerUuid]: true }));
    try {
      await onUpdateClaimStatus(winnerUuid, claimed);
      setNotification({ message: 'Claim status updated successfully', type: 'success' });
    } catch (error) {
      setNotification({ message: 'Failed to update claim status', type: 'error' });
    } finally {
      setLoadingWinners(prev => ({ ...prev, [winnerUuid]: false }));
    }
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              User
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Gameweek
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Prize
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Email
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Claimed
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {winners.map((winner) => (
            <tr key={winner.deviceUDID}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                  <div className="flex-shrink-0 h-10 w-10">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={`https://ui-avatars.com/api/?name=${winner.username}`}
                      alt=""
                    />
                  </div>
                  <div className="ml-4">
                    <div className="text-sm font-medium text-gray-900">{winner.username}</div>
                    <div className="text-sm text-gray-500">{winner.userUuid}</div>
                  </div>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{winner.gameweekId}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{winner.prize}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{winner.email}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <LoadingButton
                  isLoading={loadingWinners[winner.deviceUDID] || isUpdating}
                  onClick={() => handleClaimUpdate(winner.deviceUDID, !winner.claimed)}
                  className={`${
                    winner.claimed ? 'bg-green-600' : 'bg-yellow-600'
                  } text-white px-3 py-1 rounded-md text-sm font-medium`}
                >
                  {winner.claimed ? 'Claimed' : 'Unclaimed'}
                </LoadingButton>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {notification.message && (
        <Notification
          message={notification.message}
          type={notification.type as 'success' | 'error'}
          onClose={() => setNotification({ message: '', type: 'success' })}
        />
      )}
    </div>
  );
};

export default WinnersTable;