import React, { useState, createContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Layout from './components/layout/Layout';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Fixtures from './pages/Fixtures';
import Winners from './pages/Winners';
import Notifications from './pages/Notifications';
import Store from './pages/Store';
import Users from './pages/Users';
import Referrals from './pages/Referrals';
import Notification from './components/common/Notification';
import ErrorBoundary from './components/ErrorBoundary';
import TokenLeaderboards from './pages/TokenLeaderboards';
import NotFound from './pages/NotFound';
import { initializeSocket, getSocket } from './services/socket'; // Add this line
import UserDetail from './pages/UserDetail';

export type NotificationType = 'success' | 'error';

export interface NotificationContextType {
  setNotification: React.Dispatch<React.SetStateAction<{ message: string; type: NotificationType }>>;
}

export const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

const PrivateRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  return user ? <Layout>{children}</Layout> : <Navigate to="/login" replace />;
};


const App: React.FC = () => {
  const [notification, setNotification] = useState<{ message: string; type: NotificationType }>({ message: '', type: 'success' });

  return (
    <AuthProvider>
      <NotificationContext.Provider value={{ setNotification }}>
        <ErrorBoundary>
          <div className="App">
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
              <Route path="/dashboard" element={<Navigate to="/" replace />} />
              <Route path="/fixtures" element={<PrivateRoute><Fixtures /></PrivateRoute>} />
              <Route path="/winners" element={<PrivateRoute><Winners /></PrivateRoute>} />
              <Route path="/notifications" element={<PrivateRoute><Notifications /></PrivateRoute>} />
              <Route path="/store" element={<PrivateRoute><Store /></PrivateRoute>} />
              <Route path="/users" element={<PrivateRoute><Users /></PrivateRoute>} />
              <Route path="/users/:deviceUDID" element={<PrivateRoute><UserDetail /></PrivateRoute>} />
              <Route path="/referrals" element={<PrivateRoute><Referrals /></PrivateRoute>} />
              <Route path="/token-leaderboards" element={<PrivateRoute><TokenLeaderboards /></PrivateRoute>} />
              <Route path="*" element={<NotFound />} />
            </Routes>
            {notification.message && (
              <Notification
                message={notification.message}
                type={notification.type}
                onClose={() => setNotification({ message: '', type: 'success' })}
              />
            )}
          </div>
        </ErrorBoundary>
      </NotificationContext.Provider>
    </AuthProvider>
  );
};

export default App;