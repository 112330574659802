import React from 'react';
import { ExclamationTriangleIcon, XCircleIcon, CheckCircleIcon, InformationCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';

export type AlertType = 'warning' | 'error' | 'success' | 'info';

interface AlertProps {
  type: AlertType;
  title?: string;
  message: string;
  onDismiss?: () => void;
}

const alertStyles = {
  warning: 'bg-yellow-50 text-yellow-800',
  error: 'bg-red-50 text-red-800',
  success: 'bg-green-50 text-green-800',
  info: 'bg-blue-50 text-blue-800',
};

const iconStyles = {
  warning: 'text-yellow-400',
  error: 'text-red-400',
  success: 'text-green-400',
  info: 'text-blue-400',
};

const icons = {
  warning: ExclamationTriangleIcon,
  error: XCircleIcon,
  success: CheckCircleIcon,
  info: InformationCircleIcon,
};

const Alert: React.FC<AlertProps> = ({ type, title, message, onDismiss }) => {
  const Icon = icons[type];

  return (
    <div className={`rounded-md ${alertStyles[type]} p-4`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon className={`h-5 w-5 ${iconStyles[type]}`} aria-hidden="true" />
        </div>
        <div className="ml-3 flex-1">
          {title && <h3 className="text-sm font-medium">{title}</h3>}
          <div className={`text-sm ${title ? 'mt-2' : ''}`}>
            <p>{message}</p>
          </div>
        </div>
        {onDismiss && (
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                onClick={onDismiss}
                className={`inline-flex rounded-md ${alertStyles[type]} p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2`}
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Alert;