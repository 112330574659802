import React, { useState, useEffect } from 'react';
import { fetchStoreItems, createStoreItem, updateStoreItem, deleteStoreItem, fetchRedemptions, updateRedemptionStatus } from '../services/api';
import StoreItemCard from '../components/store/StoreItemCard';
import SalesTable from '../components/store/SalesTable';
import StoreItemForm from '../components/store/StoreItemForm';
import Drawer from '../components/common/Drawer';
import LoadingButton from '../components/common/LoadingButton';
import Notification from '../components/common/Notification';
import { useAuth } from '../contexts/AuthContext';
import { StoreItem, Redemption } from '../types/types';

const Store: React.FC = () => {
  const [activeTab, setActiveTab] = useState('store');
  const [storeItems, setStoreItems] = useState<StoreItem[]>([]);
  const [redemptions, setRedemptions] = useState<Redemption[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [notification, setNotification] = useState({ message: '', type: 'success' });
  const [editingItem, setEditingItem] = useState<StoreItem | null>(null);
  const { user } = useAuth();

  useEffect(() => {
    const loadData = async () => {
      if (!user) return;
      try {
        setLoading(true);
        const items = await fetchStoreItems();
        setStoreItems(items.filter(item => item._id));
        
        const redemptionsData = await fetchRedemptions();
        setRedemptions(redemptionsData);
      } catch (error) {
        console.error('Error fetching store data:', error);
        setError('Failed to load store data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [user]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  const handleUpdateShipment = async (redemptionId: string, shipped: boolean) => {
    try {
      const status = shipped ? 'shipped' : 'pending';
      const response = await updateRedemptionStatus(redemptionId, status);
      const updatedRedemptions = await fetchRedemptions();
      setRedemptions(updatedRedemptions);
      setNotification({ message: 'Shipment status updated successfully', type: 'success' });
    } catch (error) {
      console.error('Error updating shipment status:', error);
      setNotification({ message: 'Failed to update shipment status', type: 'error' });
    }
  };

  const handleSaveItem = async (itemData: Omit<StoreItem, '_id'>) => {
    try {
      if (editingItem && editingItem._id) {
        await updateStoreItem(editingItem._id, itemData);
      } else {
        await createStoreItem(itemData);
      }
      // Refresh store items
      const updatedItems = await fetchStoreItems();
      setStoreItems(updatedItems.filter(item => item._id));
      setIsDrawerOpen(false);
      setEditingItem(null);
      setNotification({ message: 'Item saved successfully', type: 'success' });
    } catch (error) {
      console.error('Error saving store item:', error);
      setNotification({ message: 'Failed to save item', type: 'error' });
    }
  };

  const handleAddItem = () => {
    setEditingItem(null);
    setIsDrawerOpen(true);
  };

  const handleEditItem = (item: StoreItem) => {
    setEditingItem(item);
    setIsDrawerOpen(true);
  };

  const handleDeleteItem = async (itemId: string) => {
    try {
      await deleteStoreItem(itemId);
      setNotification({ message: 'Store item deleted successfully', type: 'success' });
      const updatedItems = await fetchStoreItems();
      setStoreItems(updatedItems.filter(item => item._id));
    } catch (error) {
      setNotification({ message: 'Failed to delete store item', type: 'error' });
    }
  };

  return (
    <div>
      <h1 className="text-2xl font-semibold text-gray-900">Store</h1>
      <div className="mt-4">
        <div className="sm:hidden">
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            value={activeTab}
            onChange={(e) => setActiveTab(e.target.value)}
          >
            <option value="store">Store</option>
            <option value="sales">Sales</option>
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="flex space-x-4" aria-label="Tabs">
            <button
              onClick={() => setActiveTab('store')}
              className={`${
                activeTab === 'store'
                  ? 'bg-indigo-100 text-indigo-700'
                  : 'text-gray-500 hover:text-gray-700'
              } px-3 py-2 font-medium text-sm rounded-md`}
            >
              Store
            </button>
            <button
              onClick={() => setActiveTab('sales')}
              className={`${
                activeTab === 'sales'
                  ? 'bg-indigo-100 text-indigo-700'
                  : 'text-gray-500 hover:text-gray-700'
              } px-3 py-2 font-medium text-sm rounded-md`}
            >
              Sales
            </button>
          </nav>
        </div>
      </div>
      <div className="mt-6">
        {activeTab === 'store' ? (
          <>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
              {storeItems.map((item) => (
                <StoreItemCard 
                  key={item._id}
                  item={item}
                  onEdit={() => handleEditItem(item)}
                  onDelete={() => handleDeleteItem(item._id!)}
                />
              ))}
            </div>
            <LoadingButton
              isLoading={loading}
              onClick={handleAddItem}
              className="mt-4"
            >
              Add Store Item
            </LoadingButton>
          </>
        ) : (
          <SalesTable redemptions={redemptions} onUpdateShipment={handleUpdateShipment} />
        )}
      </div>

      <Drawer
        isOpen={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
          setEditingItem(null);
        }}
        title={editingItem ? 'Edit Store Item' : 'Add Store Item'}
      >
        <StoreItemForm
          item={editingItem || undefined}
          onSave={(itemData: Omit<StoreItem, '_id'>) => handleSaveItem(itemData)}
          onCancel={() => {
            setIsDrawerOpen(false);
            setEditingItem(null);
          }}
        />
      </Drawer>

      {notification.message && (
        <Notification
          message={notification.message}
          type={notification.type as 'success' | 'error'}
          onClose={() => setNotification({ message: '', type: 'success' })}
        />
      )}
    </div>
  );
};

export default Store;