import React, { useState, useEffect } from 'react';
import { fetchUsers } from '../services/api';
import UsersTable from '../components/users/UsersTable';
import Notification from '../components/common/Notification';
import { useAuth } from '../contexts/AuthContext';
import { User } from '../types/types';

interface UserData {
  users: User[];
  total: number;
  page: number;
  per_page: number;
}

const Users: React.FC = () => {
  const [userData, setUserData] = useState<UserData>({
    users: [],
    total: 0,
    page: 1,
    per_page: 50,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [notification, setNotification] = useState({ message: '', type: 'success' });
  const { user } = useAuth();

  useEffect(() => {
    const loadUsers = async () => {
      if (!user) return;
      setLoading(true);
      try {
        const response = await fetchUsers(userData.page, userData.per_page);
        setUserData(response.data);
      } catch (err) {
        console.error('Error fetching users:', err);
        setError('Failed to load users');
      } finally {
        setLoading(false);
      }
    };
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h1 className="text-2xl font-semibold text-gray-900">Users</h1>
      </div>
      <div className="border-t border-gray-200">
        {userData.users.length > 0 ? (
          <UsersTable users={userData.users} />
        ) : (
          <p className="px-4 py-5 sm:px-6">No users found.</p>
        )}
      </div>
      {notification.message && (
        <Notification
          message={notification.message}
          type={notification.type as 'success' | 'error'}
          onClose={() => setNotification({ message: '', type: 'success' })}
        />
      )}
    </div>
  );
};

export default Users;