import React, { useState } from 'react';
import LoadingButton from '../common/LoadingButton';
import Notification from '../common/Notification';
import { Switch } from '@headlessui/react';

interface Redemption {
  _id: string;
  deviceUDID: string;
  username: string;
  itemId: string;
  itemName: string;
  size?: string;
  email: string;
  address?: {
    addressLine1: string;
    addressLine2?: string;
    country: string;
    county: string;
    postCode: string;
  };
  status: string;
  redeemedAt: string;
}

interface SalesTableProps {
  redemptions: Redemption[];
  onUpdateShipment: (redemptionId: string, shipped: boolean) => Promise<void>;
}

const SalesTable: React.FC<SalesTableProps> = ({ redemptions, onUpdateShipment }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState({ message: '', type: 'success' });

  const handleShipmentUpdate = async (redemptionId: string, shipped: boolean) => {
    setIsLoading(true);
    try {
      await onUpdateShipment(redemptionId, shipped);
      setNotification({ message: 'Shipment status updated successfully', type: 'success' });
    } catch (error) {
      console.error('Error updating shipment status:', error);
      setNotification({ message: 'Failed to update shipment status', type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {['Date & Time', 'User', 'Item', 'Size', 'Email', 'Address', 'Status'].map((header) => (
                    <th
                      key={header}
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {redemptions.map((redemption) => (
                  <tr key={redemption._id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatDate(redemption.redeemedAt)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          <img className="h-10 w-10 rounded-full" src={`https://ui-avatars.com/api/?name=${redemption.username}`} alt="" />
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">{redemption.username}</div>
                          <div className="text-sm text-gray-500">{redemption.deviceUDID}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{redemption.itemName}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{redemption.size || 'N/A'}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{redemption.email}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {redemption.address ? `${redemption.address.addressLine1}, ${redemption.address.county}, ${redemption.address.country}, ${redemption.address.postCode}` : 'N/A'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <LoadingButton
                        isLoading={isLoading}
                        onClick={() => handleShipmentUpdate(redemption._id, redemption.status !== 'shipped')}
                        className={`${
                          redemption.status === 'shipped' ? 'bg-green-600' : 'bg-yellow-600'
                        } text-white px-3 py-1 rounded-md text-sm font-medium`}
                      >
                        {redemption.status === 'shipped' ? 'Shipped' : 'Ship Item'}
                      </LoadingButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {notification.message && (
        <Notification
          message={notification.message}
          type={notification.type as 'success' | 'error'}
          onClose={() => setNotification({ message: '', type: 'success' })}
        />
      )}
    </div>
  );
};

export default SalesTable;