import React, { useState } from 'react';
import LoadingButton from '../common/LoadingButton';
import { updateNotificationTemplate } from '../../services/api';

interface NotificationTemplateProps {
  name: string;
  content: string;
  variables?: string[]; // Make variables optional
  onSave: (name: string, content: string) => Promise<void>;
}

const NotificationTemplate: React.FC<NotificationTemplateProps> = ({ name, content, variables = [], onSave }) => {
  const [editedContent, setEditedContent] = useState(content);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');

  const handleSave = async () => {
    setSaving(true);
    setError('');
    try {
      await updateNotificationTemplate(name, editedContent);
      await onSave(name, editedContent);
    } catch (err) {
      setError('Failed to save template');
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{name}</h3>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Template</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <textarea
                rows={4}
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                value={editedContent}
                onChange={(e) => setEditedContent(e.target.value)}
              />
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Variables</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {variables.length > 0 ? variables.join(', ') : 'No variables available'}
            </dd>
          </div>
        </dl>
      </div>
      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <LoadingButton
          isLoading={saving}
          onClick={handleSave}
          className="bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500"
        >
          {saving ? 'Saving...' : 'Save'}
        </LoadingButton>
      </div>
      {error && <div className="px-4 py-3 text-red-500">{error}</div>}
    </div>
  );
};

export default NotificationTemplate;