import React, { useState, useEffect } from 'react';
import { fetchWinners, updateWinnerClaimStatus } from '../services/api';
import WinnersTable from '../components/winners/WinnersTable';
import Notification from '../components/common/Notification';
import { useAuth } from '../contexts/AuthContext';
import { Winner } from '../types/types';


const Winners: React.FC = () => {
  const [winners, setWinners] = useState<Winner[]>([]);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState('');
  const [notification, setNotification] = useState({ message: '', type: 'success' });
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      loadWinners();
    }
  }, [user]);

  const loadWinners = async () => {
    setLoading(true);
    try {
      const response = await fetchWinners();
      setWinners(response.data);
      setError(''); // Clear any previous errors
    } catch (err) {
      console.error('Error fetching winners:', err);
      setError('Failed to load winners');
      setNotification({ message: 'Failed to load winners', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateClaimStatus = async (winnerId: string, claimed: boolean) => {
    setUpdating(true);
    try {
      await updateWinnerClaimStatus(winnerId, claimed);
      setWinners(winners.map(winner => 
        winner.deviceUDID === winnerId ? { ...winner, claimed } : winner
      ));
      setNotification({ message: 'Claim status updated successfully', type: 'success' });
    } catch (error) {
      setNotification({ message: 'Failed to update claim status', type: 'error' });
    } finally {
      setUpdating(false);
    }
  };

  if (loading) return <div className="flex justify-center items-center h-screen">Loading...</div>;
  if (error) return <div className="text-red-500 text-center mb-4">{error}</div>;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="mb-6">
        <h1 className="text-2xl font-semibold text-gray-900">Winners</h1>
      </div>
      
      {winners.length > 0 ? (
        <WinnersTable 
          winners={winners} 
          onUpdateClaimStatus={handleUpdateClaimStatus}
          isUpdating={updating}
        />
      ) : (
        <p className="text-center text-gray-500">No winners found.</p>
      )}

      {notification.message && (
        <Notification
          message={notification.message}
          type={notification.type as 'success' | 'error'}
          onClose={() => setNotification({ message: '', type: 'success' })}
        />
      )}
    </div>
  );
};

export default Winners;