import React from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { parseISO, format, isValid } from 'date-fns';
import { Fixture } from '../../types/types';

interface FixtureTableProps {
  fixtures: Fixture[];
  selectedFixtures: string[];
  isEditing: boolean;
  fixturesChanged: boolean;
  onFixtureSelect: (fixtureId: string) => void;
}

const FixtureTable: React.FC<FixtureTableProps> = ({
  fixtures,
  selectedFixtures,
  isEditing,
  fixturesChanged,
  onFixtureSelect
}) => {
  console.log('FixtureTable selectedFixtures:', selectedFixtures);

  const formatKickoffTime = (kickoffTime: string) => {
    const parsedDate = parseISO(kickoffTime);
    return isValid(parsedDate) ? format(parsedDate, 'PPpp') : 'TBA';
  };

  const getScore = (fixture: Fixture) => {
    if (fixture.team_h_score !== null && fixture.team_a_score !== null) {
      return `${fixture.team_h_score} - ${fixture.team_a_score}`;
    }
    return 'N/A';
  };

  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Selected
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Home Team
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Away Team
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Date & Time
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Status
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Score
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Highest Scoring Player
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {fixtures.map((fixture) => (
                <tr key={fixture.id.toString()}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {isEditing ? (
                      <input
                        type="checkbox"
                        checked={selectedFixtures.includes(fixture.id.toString())}
                        onChange={() => onFixtureSelect(fixture.id.toString())}
                        disabled={!selectedFixtures.includes(fixture.id.toString()) && selectedFixtures.length >= 6}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    ) : selectedFixtures.includes(fixture.id.toString()) ? (
                      <CheckIcon className="h-5 w-5 text-green-500" />
                    ) : null}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{fixture.homeTeam}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{fixture.awayTeam}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {formatKickoffTime(fixture.kickoff_time)}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      fixture.status === 'finished'
                        ? 'bg-green-100 text-green-800'
                        : fixture.status === 'in_progress'
                        ? 'bg-yellow-100 text-yellow-800'
                        : 'bg-gray-100 text-gray-800'
                    }`}>
                      {fixture.status}
                    </span>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {getScore(fixture)}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {fixture.highest_scoring_player ? (
                      <div>
                        <p>{fixture.highest_scoring_player.name}</p>
                        <p>Points: {fixture.highest_scoring_player.points}</p>
                        <p>BPS: {fixture.highest_scoring_player.bps}</p>
                        <p>Minutes: {fixture.highest_scoring_player.minutes}</p>
                      </div>
                    ) : (
                      'TBA'
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default FixtureTable;