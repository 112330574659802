import React, { useState } from 'react';
import LoadingButton from '../common/LoadingButton';
import Notification from '../common/Notification';

interface StoreItem {
  _id: string;
  title: string;
  description: string;
  tokenCost: number;
  itemType: 'Physical' | 'Digital';
  imageUrl: string;
  stockQuantity?: number;
  isClothing: boolean;
  sizes?: { [key: string]: number };
}

interface StoreItemCardProps {
  item: StoreItem;
  onEdit: (item: StoreItem) => void;
  onDelete: (itemId: string) => Promise<void>;
}

const StoreItemCard: React.FC<StoreItemCardProps> = ({ item, onEdit, onDelete }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState({ message: '', type: 'success' });

  const handleEdit = () => {
    onEdit(item);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await onDelete(item._id);
      setNotification({ message: 'Item deleted successfully', type: 'success' });
    } catch (error) {
      setNotification({ message: 'Failed to delete item', type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="overflow-hidden rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:p-6">
        <div className="flex items-center justify-between">
          <h3 className="text-lg font-medium leading-6 text-gray-900">{item.title}</h3>
          <div className="flex space-x-2">
            <LoadingButton
              isLoading={isLoading}
              onClick={handleEdit}
              className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Edit
            </LoadingButton>
            <LoadingButton
              isLoading={isLoading}
              onClick={handleDelete}
              className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Delete
            </LoadingButton>
          </div>
        </div>
        <div className="mt-4 max-w-xl text-sm text-gray-500">
          <p>{item.description}</p>
        </div>
        <div className="mt-5">
          <div className="rounded-md bg-gray-50 px-6 py-5">
            <div className="flex items-center justify-between">
              <p className="text-sm font-medium text-gray-900">Token Cost: {item.tokenCost}</p>
              <img className="h-20 w-20 object-cover rounded" src={item.imageUrl} alt={item.title} />
            </div>
            <p className="mt-2 text-sm text-gray-500">Type: {item.itemType}</p>
            {item.itemType === 'Physical' && (
              <p className="mt-2 text-sm text-gray-500">Stock: {item.stockQuantity}</p>
            )}
            {item.isClothing && item.sizes && (
              <div className="mt-2">
                <p className="text-sm font-medium text-gray-900">Sizes:</p>
                <div className="mt-1 grid grid-cols-5 gap-2">
                  {Object.entries(item.sizes).map(([size, quantity]) => (
                    <p key={size} className="text-sm text-gray-500">{size}: {quantity}</p>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {notification.message && (
        <Notification
          message={notification.message}
          type={notification.type as 'success' | 'error'}
          onClose={() => setNotification({ message: '', type: 'success' })}
        />
      )}
    </div>
  );
};

export default StoreItemCard;