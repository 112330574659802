import React, { useState, useEffect } from 'react';
import LoadingButton from '../common/LoadingButton';
import { updateReferralSettings } from '../../services/api';
import { ReferralSettings as ReferralSettingsType } from '../../types/types';

interface ReferralSettingsProps {
  settings: ReferralSettingsType;
  onSave: (newSettings: ReferralSettingsType) => Promise<void>;
}

const ReferralSettings: React.FC<ReferralSettingsProps> = ({ settings, onSave }) => {
  const [editedSettings, setEditedSettings] = useState(settings);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setEditedSettings(settings);
  }, [settings]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedSettings((prev) => ({
      ...prev,
      [name]: name === 'appStoreLink' ? value : parseInt(value, 10),
    }));
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      await onSave(editedSettings);
      // ... success handling ...
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="space-y-8 divide-y divide-gray-200">
      <div>
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label htmlFor="referrerReward" className="block text-sm font-medium text-gray-700">
              Referrer Reward
            </label>
            <div className="mt-1">
              <input
                type="number"
                name="referrerReward"
                id="referrerReward"
                value={editedSettings.referrerReward}
                onChange={handleChange}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label htmlFor="referredReward" className="block text-sm font-medium text-gray-700">
              Referred Reward
            </label>
            <div className="mt-1">
              <input
                type="number"
                name="referredReward"
                id="referredReward"
                value={editedSettings.referredReward}
                onChange={handleChange}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label htmlFor="requiredGameweeks" className="block text-sm font-medium text-gray-700">
              Required Gameweeks
            </label>
            <div className="mt-1">
              <input
                type="number"
                name="requiredGameweeks"
                id="requiredGameweeks"
                value={editedSettings.requiredGameweeks}
                onChange={handleChange}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>

          <div className="sm:col-span-6">
            <label htmlFor="appStoreLink" className="block text-sm font-medium text-gray-700">
              App Store Link
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="appStoreLink"
                id="appStoreLink"
                value={editedSettings.appStoreLink}
                onChange={handleChange}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <LoadingButton
            onClick={handleSave}
            isLoading={saving}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save
          </LoadingButton>
        </div>
      </div>
    </div>
  );
}

export default ReferralSettings;