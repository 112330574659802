import React, { useState } from 'react';
import { Switch } from '@headlessui/react';
import { StoreItem } from '../../types/types';

interface StoreItemFormProps {
  item?: Partial<Omit<StoreItem, '_id'>> & { _id?: string };
  onSave: (item: Omit<StoreItem, '_id'>) => void;
  onCancel: () => void;
}

const StoreItemForm: React.FC<StoreItemFormProps> = ({ item, onSave, onCancel }) => {
  const [formData, setFormData] = useState<Omit<StoreItem, '_id'>>({
    title: item?.title || '',
    description: item?.description || '',
    tokenCost: item?.tokenCost || 0,
    itemType: item?.itemType || 'Physical',
    imageUrl: item?.imageUrl || '',
    isClothing: item?.isClothing || false,
    sizes: item?.sizes || { XS: 0, S: 0, M: 0, L: 0, XL: 0 },
    stockQuantity: item?.stockQuantity || 0,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: parseInt(value) || 0 }));
  };

  const handleSwitchChange = (checked: boolean) => {
    setFormData(prev => ({ ...prev, isClothing: checked }));
  };

  const handleSizeChange = (size: string, value: number) => {
    setFormData(prev => ({
      ...prev,
      sizes: { ...prev.sizes!, [size]: value },
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title</label>
        <input
          type="text"
          name="title"
          id="title"
          value={formData.title}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          required
        />
      </div>
      <div>
        <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
        <textarea
          name="description"
          id="description"
          value={formData.description}
          onChange={handleChange}
          rows={3}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          required
        />
      </div>
      <div>
        <label htmlFor="tokenCost" className="block text-sm font-medium text-gray-700">Token Cost</label>
        <input
          type="number"
          name="tokenCost"
          id="tokenCost"
          value={formData.tokenCost}
          onChange={handleNumberChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          required
        />
      </div>
      <div>
        <label htmlFor="itemType" className="block text-sm font-medium text-gray-700">Item Type</label>
        <select
          name="itemType"
          id="itemType"
          value={formData.itemType}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          <option value="Physical">Physical</option>
          <option value="Digital">Digital</option>
        </select>
      </div>
      <div>
        <label htmlFor="imageUrl" className="block text-sm font-medium text-gray-700">Image URL</label>
        <input
          type="text"
          name="imageUrl"
          id="imageUrl"
          value={formData.imageUrl}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          required
        />
      </div>
      {formData.itemType === 'Physical' && (
        <>
          <div>
            <label htmlFor="stockQuantity" className="block text-sm font-medium text-gray-700">Stock Quantity</label>
            <input
              type="number"
              name="stockQuantity"
              id="stockQuantity"
              value={formData.stockQuantity || 0}
              onChange={handleNumberChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <Switch.Group as="div" className="flex items-center">
            <Switch
              checked={formData.isClothing}
              onChange={handleSwitchChange}
              className={`${
                formData.isClothing ? 'bg-indigo-600' : 'bg-gray-200'
              } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
            >
              <span
                aria-hidden="true"
                className={`${
                  formData.isClothing ? 'translate-x-5' : 'translate-x-0'
                } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
            <Switch.Label as="span" className="ml-3 text-sm">
              <span className="font-medium text-gray-900">Is Clothing</span>
            </Switch.Label>
          </Switch.Group>
        </>
      )}
      {formData.isClothing && formData.sizes && (
        <div className="grid grid-cols-5 gap-4">
          {(['XS', 'S', 'M', 'L', 'XL'] as const).map((size) => (
            <div key={size}>
              <label htmlFor={`size-${size}`} className="block text-sm font-medium text-gray-700">{size}</label>
              <input
                type="number"
                id={`size-${size}`}
                value={formData.sizes?.[size] ?? 0}
                onChange={(e) => handleSizeChange(size, parseInt(e.target.value) || 0)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          ))}
        </div>
      )}
      <div className="flex justify-end space-x-4">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default StoreItemForm;